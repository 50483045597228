import { appBar, theme } from "../../../utils/theme";

export const styles = {
  actionButton: {
    background: theme.palette.primary.linearGradient,
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  selectMenu: {
    alignItems: "flex-start",
    backgroundColor: theme.palette.grays["white"],
    borderColor: theme.palette.grays[300],
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: 0,
    boxShadow: "0 0 24px rgba(37,43,129,.04), 0 44px 74px rgba(37,43,129,.06)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    pb: 4,
    pt: 4,
  },
  selectMenuContainer: {
    pb: 4,
    pt: `calc(${(appBar.height * 1) / 3}px)`,
    position: "absolute",
    left: -40,
    top: `calc(${(appBar.height * 2) / 3}px + 1px)`,
    width: 440,
    zIndex: 0,
  },
  selectOption: {
    alignItems: "start",
    display: "flex",
    justifyContent: "flex-start",
    pb: 4,
    pl: 8,
    pr: 8,
    pt: 4,
    transition: "all 0.3s ease-in-out",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.grays[100],
      // color: theme.palette.primary.main,
    },
  },
  selectOptionBody: {
    color: theme.palette.grays[500],
    fontSize: theme.typography.body2,
    fontWeight: 400,
  },
  selectOptionHeader: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h5,
    fontWeight: 600,
  },
  selectOptionImage: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderRadius: 1,
    height: 32,
    mr: 3,
    width: 32,
  },
  selectOptionLabel: {
    fontSize: theme.typography.fontSize * 0.88,
    marginRight: 2,
    whiteSpace: "nowrap",
  },
  selectOptionLabelActive: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
};
